import { MessageMap } from 'deliver-portal-commons';

const ptBR: MessageMap = {
  Hive_CountriesLabel: {
    AD: 'Andorra',
    AE: 'Emirados Árabes Unidos',
    AF: 'Afeganistão',
    AG: 'Antígua e Barbuda',
    AI: 'Anguilla',
    AL: 'Albânia',
    AM: 'Armênia',
    AO: 'Angola',
    AQ: 'Antártica',
    AR: 'Argentina',
    AS: 'Samoa Americana',
    AT: 'Áustria',
    AU: 'Austrália',
    AW: 'Aruba',
    AZ: 'Azerbaijão',
    BA: 'Bósnia e Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Bélgica',
    BF: 'Burkina Faso',
    BG: 'Bulgária',
    BH: 'Bahrein',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'São Bartolomeu',
    BM: 'Bermudas',
    BN: 'Brunei',
    BO: 'Bolívia',
    BR: 'Brasil',
    BS: 'Bahamas',
    BT: 'Butão',
    BW: 'Botsuana',
    BY: 'Bielorrússia',
    BZ: 'Belize',
    CA: 'Canadá',
    CC: 'Ilhas Cocos',
    CD: 'República Democrática do Congo',
    CF: 'República Centro-Africana',
    CG: 'República do Congo',
    CH: 'Suíça',
    CI: 'Costa do Marfim',
    CL: 'Chile',
    CM: 'Camarões',
    CN: 'China',
    CO: 'Colômbia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cabo Verde',
    CW: 'Curacao',
    CX: 'Ilha Christmas',
    CY: 'Chipre',
    CZ: 'República Tcheca',
    DE: 'Alemanha',
    DJ: 'Djibuti',
    DK: 'Dinamarca',
    DM: 'Dominica',
    DO: 'República Dominicana',
    DZ: 'Argélia',
    EC: 'Equador',
    EE: 'Estônia',
    EG: 'Egito',
    EH: 'Saara Ocidental',
    ER: 'Eritreia',
    ES: 'Espanha',
    ET: 'Etiópia',
    FI: 'Finlândia',
    FJ: 'Fiji',
    FM: 'Micronésia',
    FO: 'Ilhas Faroé',
    FR: 'França',
    GA: 'Gabão',
    GB: 'Reino Unido',
    GD: 'Granada',
    GE: 'Geórgia',
    GF: 'Guiana Francesa',
    GG: 'Guernsey',
    GH: 'Gana',
    GI: 'Gibraltar',
    GL: 'Groenlândia',
    GM: 'Gâmbia',
    GN: 'Guiné',
    GP: 'Guadalupe',
    GQ: 'Guiné Equatorial',
    GR: 'Grécia',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guiné-Bissau',
    GY: 'Guiana',
    HK: 'Hong Kong',
    HN: 'Honduras',
    HR: 'Croácia',
    HT: 'Haiti',
    HU: 'Hungria',
    ID: 'Indonésia',
    IE: 'Irlanda',
    IL: 'Israel',
    IM: 'Ilha de Man',
    IN: 'Índia',
    IO: 'Território Britânico do Oceano Índico',
    IQ: 'Iraque',
    IR: 'Irã',
    IS: 'Islândia',
    IT: 'Itália',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordânia',
    JP: 'Japão',
    KE: 'Quênia',
    KG: 'Quirguistão',
    KH: 'Camboja',
    KI: 'Quiribati',
    KM: 'Comores',
    KN: 'São Cristóvão e Neves',
    KP: 'Coreia do Norte',
    KR: 'Coreia do Sul',
    KW: 'Kuwait',
    KY: 'Ilhas Cayman',
    KZ: 'Cazaquistão',
    LA: 'Laos',
    LB: 'Líbano',
    LC: 'Santa Lúcia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Libéria',
    LS: 'Lesoto',
    LT: 'Lituânia',
    LU: 'Luxemburgo',
    LV: 'Letônia',
    LY: 'Líbia',
    MA: 'Marrocos',
    MC: 'Mônaco',
    MD: 'Moldávia',
    ME: 'Montenegro',
    MF: 'São Martinho',
    MG: 'Madagascar',
    MH: 'Ilhas Marshall',
    MK: 'Macedônia do Norte',
    ML: 'Mali',
    MM: 'Mianmar',
    MN: 'Mongólia',
    MO: 'Macau',
    MP: 'Ilhas Marianas do Norte',
    MQ: 'Martinica',
    MR: 'Mauritânia',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Maurício',
    MV: 'Maldivas',
    MW: 'Malawi',
    MX: 'México',
    MY: 'Malásia',
    MZ: 'Moçambique',
    NA: 'Namíbia',
    NC: 'Nova Caledônia',
    NE: 'Níger',
    NF: 'Ilha Norfolk',
    NG: 'Nigéria',
    NI: 'Nicarágua',
    NL: 'Países Baixos',
    NO: 'Noruega',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'Nova Zelândia',
    OM: 'Omã',
    PA: 'Panamá',
    PE: 'Peru',
    PF: 'Polinésia Francesa',
    PG: 'Papua-Nova Guiné',
    PH: 'Filipinas',
    PK: 'Paquistão',
    PL: 'Polônia',
    PM: 'São Pedro e Miquelão',
    PN: 'Ilhas Pitcairn',
    PR: 'Porto Rico',
    PS: 'Palestina',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguai',
    QA: 'Catar',
    RE: 'Reunião',
    RO: 'Romênia',
    RS: 'Sérvia',
    RU: 'Rússia',
    RW: 'Ruanda',
    SA: 'Arábia Saudita',
    SB: 'Ilhas Salomão',
    SC: 'Seicheles',
    SD: 'Sudão',
    SE: 'Suécia',
    SG: 'Singapura',
    SH: 'Santa Helena',
    SI: 'Eslovênia',
    SJ: 'Svalbard e Jan Mayen',
    SK: 'Eslováquia',
    SL: 'Serra Leoa',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somália',
    SR: 'Suriname',
    SS: 'Sudão do Sul',
    ST: 'São Tomé e Príncipe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Síria',
    SZ: 'Suazilândia',
    TC: 'Ilhas Turcas e Caicos',
    TD: 'Chade',
    TF: 'Territórios Franceses do Sul',
    TG: 'Togo',
    TH: 'Tailândia',
    TJ: 'Tajiquistão',
    TK: 'Toquelau',
    TL: 'Timor-Leste',
    TM: 'Turcomenistão',
    TN: 'Tunísia',
    TO: 'Tonga',
    TR: 'Turquia',
    TT: 'Trinidad e Tobago',
    TV: 'Tuvalu',
    TZ: 'Tanzânia',
    UA: 'Ucrânia',
    UG: 'Uganda',
    US: 'Estados Unidos',
    UY: 'Uruguai',
    UZ: 'Uzbequistão',
    VA: 'Vaticano',
    VC: 'São Vicente e Granadinas',
    VE: 'Venezuela',
    VG: 'Ilhas Virgens Britânicas',
    VI: 'Ilhas Virgens Americanas',
    VN: 'Vietnã',
    VU: 'Vanuatu',
    WF: 'Wallis e Futuna',
    WS: 'Samoa',
    YE: 'Iémen',
    YT: 'Mayotte',
    ZA: 'África do Sul',
    ZM: 'Zâmbia',
    ZW: 'Zimbábue',
  },
  Hive_DriverPortfolioPage: {
    TITLE: 'Gerenciamento de motoristas e carteira',
    SIDEBAR_TITLE: 'Gestão de motoristas',
    SEARCH_DRIVER: 'Pesquisar por nome do motorista, ID ou placa do veículo ',
    REGISTER_DRIVERS: 'Registrar motoristas no Data management',
    NO_DRIVERS_REGISTERED: 'Nenhum motorista registrado',
    ONCE_REGISTERED: 'Depois de registrados, os drivers aparecerão nesta seção',
    REGISTER_DRIVERS_SHORT: 'Registrar motoristas',
    DRIVER_ID: 'ID',
    DRIVER_NAME: 'Nome',
    VEHICLE: 'Caminhão',
    POCS: 'PDVs',
    ACTIONS: 'Ações',
    ERROR_MESSAGE:
      'Falha ao carregar lista de motoristas. Tente novamente mais tarde.',
  },
  Hive_DriverProfilePage: {
    DRIVER_DETAILS: 'Detalhes do motorista',
    EDIT_BUTTON: 'Editar',
    CANCEL_BUTTON: 'Cancelar',
    SAVE_CHANGES_BUTTON: 'Salvar alterações',
    DRIVER_NAME: 'Nome',
    DRIVER_ID: 'ID',
    DRIVER_TRUCK: 'Caminhão',
    DRIVER_PHONE: 'Telefone',
    CUSTOMER_PORTFOLIO_TITLE: 'Carteira de clientes',
    TABLE_SEARCH_PLACEHOLDER: 'Pesquisar por nome ou ID do PDV',
    TABLE_COLUMN_POC_ID: 'ID PDV',
    TABLE_COLUMN_POC_NAME: 'Nome do PDV',
    TABLE_COLUMN_ADDRESS: 'Endereço',
    TABLE_EMPTY_NO_POC_REGISTERED:
      'Uma vez registrados, os PDVs aparecerão nesta seção.',
    TABLE_EMPTY_NO_POC_FOUND:
      'Nenhum resultado encontrado. Verifique a ortografia ou tente palavras-chave diferentes.',
    SEARCH_DRIVER: 'Busca',
    REASSIGN_POCS: 'Reatribuir PDVs',
    CONFIRM_REASSIGN_MODAL_TITLE: 'Reatribuir PDVs?',
    CONFIRM_REASSIGN_MODAL_MESSAGE:
      'Os PDVs selecionados serão reatribuídos ao motorista selecionado.',
    CONFIRM_REASSIGN_MODAL_SAVE_CHANGES_BUTTON: 'Salvar alterações',
    CONFIRM_REASSIGN_MODAL_CANCEL_BUTTON: 'Voltar',
    CONFIRM_REASSIGN_MODAL_CONFIRM_BUTTON: 'Reassignar',
    DELETE_POC_MODAL_TITLE: 'Remover PDV?',
    DELETE_POC_MODAL_MESSAGE:
      'Isso removerá permanentemente o PDV. Isto não pode ser desfeito.',
    DELETE_POC_MODAL_CONFIRM_BUTTON: 'Remover PDV',
    DELETE_POC_MODAL_CANCEL_BUTTON: 'Voltar',
    DISCARD_CHANGES_TITLE: 'Descartar alterações?',
    DISCARD_CHANGES_DESCRIPTION:
      'Todas as mudanças não salvas serão descartadas.',
    DISCARD_CHANGES_CONFIRMATION: 'Descartar alterações',
    DISCARD_CHANGES_GO_BACK: 'Voltar',
    ERROR_LOAD_MESSAGE:
      'Falha ao carregar detalhes do motorista. Tente novamente mais tarde.',
    ERROR_UPDATE_MESSAGE:
      'Falha ao atualizar as informações do motorista. Tente novamente mais tarde.',
    SUCCESS_UPDATE_MESSAGE: 'Informações do motorista atualizadas com sucesso',
    ERROR_REASSIGN_MESSAGE:
      'Falha ao reatribuir o PDV. Tente novamente mais tarde.',
  },
  Hive_RoutesPage: {
    TITLE: 'Rotas',
    SIDEBAR_TITLE: 'Rotas',
    CREATE_ROUTES: 'Criar rotas',
    SCHEDULED_TAB: 'Agendadas',
    DRAFTS_TAB: 'Rascunhos',
    TABLE_SEARCH: 'Pesquisar por motorista ou ID da rota',
    SELECT_DATE: 'Selecionar data',
    YESTERDAY: 'Ontem',
    TODAY: 'Hoje',
    TOMORROW: 'Amanhã',
    ROUTE_ID: 'ID da rota',
    DATE: 'Data',
    DRIVER: 'Motorista',
    TRUCK: 'Caminhão',
    VISITS: 'Visitas',
    EMPTY_ROUTES_TITLE: 'Nenhuma rota criada',
    EMPTY_ROUTES_DESCRIPTION:
      'Uma vez criadas, as rotas aparecerão nesta seção.',
    EMPTY_ROUTES_BUTTON: 'Criar rotas',
    EDIT_DRAFT: 'Editar rascunho',
    DELETE_DRAFT: 'Excluir rascunho',
    DRAFT_ID: 'ID do rascunho',
    CREATED_BY: 'Criado por',
    CREATED_ON: 'Criado em',
    ACTIONS: 'Ações',
  },
  Hive_CreateRoutesPage: {
    TITLE: 'Criar rotas',
    MAP_LEGEND: 'PDVs sem caminhão ou motorista',
    MAP_MARKERS_UNASSIGNED: 'Visita não atribuída',
  },
  HIVE_ProgressStepper: {
    ROUTE_SETUP: 'Configuração de rota',
    ORGANIZE_ROUTES: 'Organizar rotas',
    ROUTE_SUMMARY: 'Revisar',
  },
  Hive_OrganizeRouteStep: {
    ROUTES: 'rotas',
    TO: 'De',
  },
  HIVE_RouteSearchBar: {
    SEARCH_POC: 'Pesquisar por PDV ou caminhão',
  },
  HIVE_AddOrderDisplay: {
    ADD_ORDERS: 'Adicione pedidos para criar rotas',
    ADD_ORDERS_BUTTON: 'Adicionar pedidos',
  },
  HIVE_OrganizeRoutesFooter: {
    GO_BACK: 'Voltar',
    CONTINUE: 'Continuar',
  },
  HIVE_RouteSetupFooter: {
    CONTINUE: 'Continuar',
  },
  HIVE_RouteSetupStep: {
    SELECT_DATE: 'Selecionar data',
    ROUTE_DATE: 'Data da rota',
    ROUTE_DATE_SUBTITLE: 'Quando a rota será realizada?',
  },
};

export default {
  messages: ptBR,
  language: 'pt-BR',
};
