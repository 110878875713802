import { MessageMap } from 'deliver-portal-commons';

const en: MessageMap = {
  Hive_CountriesLabel: {
    AD: 'Andorra',
    AE: 'United Arab Emirates',
    AF: 'Afghanistan',
    AG: 'Antigua and Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AO: 'Angola',
    AQ: 'Antarctica',
    AR: 'Argentina',
    AS: 'American Samoa',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AZ: 'Azerbaijan',
    BA: 'Bosnia and Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgium',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'Saint Barthélemy',
    BM: 'Bermuda',
    BN: 'Brunei',
    BO: 'Bolivia',
    BR: 'Brazil',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BW: 'Botswana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Cocos Islands',
    CD: 'Democratic Republic of the Congo',
    CF: 'Central African Republic',
    CG: 'Republic of the Congo',
    CH: 'Switzerland',
    CI: 'Ivory Coast',
    CL: 'Chile',
    CM: 'Cameroon',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cape Verde',
    CW: 'Curacao',
    CX: 'Christmas Island',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DE: 'Germany',
    DJ: 'Djibouti',
    DK: 'Denmark',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egypt',
    EH: 'Western Sahara',
    ER: 'Eritrea',
    ES: 'Spain',
    ET: 'Ethiopia',
    FI: 'Finland',
    FJ: 'Fiji',
    FM: 'Micronesia',
    FO: 'Faroe Islands',
    FR: 'France',
    GA: 'Gabon',
    GB: 'United Kingdom',
    GD: 'Grenada',
    GE: 'Georgia',
    GF: 'French Guiana',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Greenland',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Equatorial Guinea',
    GR: 'Greece',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    ID: 'Indonesia',
    IE: 'Ireland',
    IL: 'Israel',
    IM: 'Isle of Man',
    IN: 'India',
    IO: 'British Indian Ocean Territory',
    IQ: 'Iraq',
    IR: 'Iran',
    IS: 'Iceland',
    IT: 'Italy',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KI: 'Kiribati',
    KM: 'Comoros',
    KN: 'Saint Kitts and Nevis',
    KP: 'North Korea',
    KR: 'South Korea',
    KW: 'Kuwait',
    KY: 'Cayman Islands',
    KZ: 'Kazakhstan',
    LA: 'Laos',
    LB: 'Lebanon',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    LY: 'Libya',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova',
    ME: 'Montenegro',
    MF: 'Saint Martin',
    MG: 'Madagascar',
    MH: 'Marshall Islands',
    MK: 'North Macedonia',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolia',
    MO: 'Macao',
    MP: 'Northern Mariana Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'New Zealand',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'French Polynesia',
    PG: 'Papua New Guinea',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Poland',
    PM: 'Saint Pierre and Miquelon',
    PN: 'Pitcairn Islands',
    PR: 'Puerto Rico',
    PS: 'Palestine',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russia',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SB: 'Solomon Islands',
    SC: 'Seychelles',
    SD: 'Sudan',
    SE: 'Sweden',
    SG: 'Singapore',
    SH: 'Saint Helena',
    SI: 'Slovenia',
    SJ: 'Svalbard and Jan Mayen',
    SK: 'Slovakia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    SS: 'South Sudan',
    ST: 'Sao Tome and Principe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Syria',
    SZ: 'Swaziland',
    TC: 'Turks and Caicos Islands',
    TD: 'Chad',
    TF: 'French Southern Territories',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TK: 'Tokelau',
    TL: 'East Timor',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Turkey',
    TT: 'Trinidad and Tobago',
    TV: 'Tuvalu',
    TZ: 'Tanzania',
    UA: 'Ukraine',
    UG: 'Uganda',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Vatican City',
    VC: 'Saint Vincent and the Grenadines',
    VE: 'Venezuela',
    VG: 'British Virgin Islands',
    VI: 'U.S. Virgin Islands',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis and Futuna',
    WS: 'Samoa',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
  Hive_DriverPortfolioPage: {
    TITLE: 'Drivers and portfolio management',
    SIDEBAR_TITLE: "Driver's management",
    SEARCH_DRIVER: 'Search by driver name, ID or license plate',
    REGISTER_DRIVERS: 'Register drivers on Data management',
    NO_DRIVERS_REGISTERED: 'No drivers registered',
    ONCE_REGISTERED: 'Once registered, the drivers will appear in this section',
    REGISTER_DRIVERS_SHORT: 'Register drivers',
    DRIVER_ID: 'ID',
    DRIVER_NAME: 'Name',
    VEHICLE: 'Truck',
    POCS: 'POCs in portfolio',
    ACTIONS: 'Actions',
    ERROR_MESSAGE: 'Failed to load drivers list. Try again later.',
  },
  Hive_DriverProfilePage: {
    DRIVER_DETAILS: 'Driver details',
    EDIT_BUTTON: 'Edit',
    CANCEL_BUTTON: 'Cancel',
    SAVE_CHANGES_BUTTON: 'Save changes',
    DRIVER_NAME: 'Name',
    DRIVER_ID: 'ID',
    DRIVER_TRUCK: 'Truck',
    DRIVER_PHONE: 'Phone',
    CUSTOMER_PORTFOLIO_TITLE: 'Customer Portfolio',
    TABLE_SEARCH_PLACEHOLDER: 'Search by POC name or ID',
    TABLE_COLUMN_POC_ID: 'POC ID',
    TABLE_COLUMN_POC_NAME: 'POC name',
    TABLE_COLUMN_ADDRESS: 'Address',
    TABLE_EMPTY_NO_POC_REGISTERED:
      'Once registered, the POCs will appear in this section.',
    TABLE_EMPTY_NO_POC_FOUND:
      'No results found. Check the spelling or try different keywords.',
    SEARCH_DRIVER: 'Search driver',
    REASSIGN_POCS: 'Reassign POCs',
    CONFIRM_REASSIGN_MODAL_TITLE: 'Reassign POCs?',
    CONFIRM_REASSIGN_MODAL_MESSAGE:
      'The selected POCs will be reassigned to the selected driver.',
    CONFIRM_REASSIGN_MODAL_SAVE_CHANGES_BUTTON: 'Save changes',
    CONFIRM_REASSIGN_MODAL_CANCEL_BUTTON: 'Go back',
    CONFIRM_REASSIGN_MODAL_CONFIRM_BUTTON: 'Reassign',
    DELETE_POC_MODAL_TITLE: 'Delete POC?',
    DELETE_POC_MODAL_MESSAGE:
      'This will permanently delete the POC. This cannot be undone.',
    DELETE_POC_MODAL_CONFIRM_BUTTON: 'Delete POC',
    DELETE_POC_MODAL_CANCEL_BUTTON: 'Go back',
    DISCARD_CHANGES_TITLE: 'Discard changes?',
    DISCARD_CHANGES_DESCRIPTION: 'All unsaved changes will be discarded.',
    DISCARD_CHANGES_CONFIRMATION: 'Discard changes',
    DISCARD_CHANGES_GO_BACK: 'Go back',
    ERROR_LOAD_MESSAGE: 'Failed to load driver details. Try again later.',
    ERROR_UPDATE_MESSAGE:
      'Failed to update driver information. Try again later.',
    SUCCESS_UPDATE_MESSAGE: 'Driver information successfully updated',
    ERROR_REASSIGN_MESSAGE: 'Failed to reassign POC. Try again later.',
  },
  Hive_RoutesPage: {
    TITLE: 'Routes',
    SIDEBAR_TITLE: 'Routes',
    CREATE_ROUTES: 'Create routes',
    SCHEDULED_TAB: 'Scheduled',
    DRAFTS_TAB: 'Drafts',
    TABLE_SEARCH: 'Search by driver or route ID',
    SELECT_DATE: 'Select date',
    YESTERDAY: 'Yesterday',
    TODAY: 'Today',
    TOMORROW: 'Tomorrow',
    ROUTE_ID: 'Route ID',
    DATE: 'Date',
    DRIVER: 'Driver',
    TRUCK: 'Truck',
    VISITS: 'Visits',
    EMPTY_ROUTES_TITLE: 'No routes created',
    EMPTY_ROUTES_DESCRIPTION:
      'Any routes created on the route planning tool will appear in this section',
    EMPTY_ROUTES_BUTTON: 'Create routes',
    EDIT_DRAFT: 'Edit draft',
    DELETE_DRAFT: 'Delete draft',
    DRAFT_ID: 'Draft ID',
    CREATED_BY: 'Created by',
    CREATED_ON: 'Created on',
    ACTIONS: 'Actions',
  },
  Hive_CreateRoutesPage: {
    TITLE: 'Create routes',
    MAP_LEGEND: 'POCs without truck or driver',
    MAP_MARKERS_UNASSIGNED: 'Unassigned visit',
  },
  HIVE_ProgressStepper: {
    ROUTE_SETUP: 'Route setup',
    ORGANIZE_ROUTES: 'Organize routes',
    ROUTE_SUMMARY: 'Route summary',
  },
  Hive_OrganizeRouteStep: {
    ROUTES: 'routes',
    TO: 'To',
  },
  HIVE_RouteSearchBar: {
    SEARCH_POC: 'Search by POC or truck',
  },
  HIVE_AddOrderDisplay: {
    ADD_ORDERS: 'Add orders to create routes',
    ADD_ORDERS_BUTTON: 'Add orders',
  },
  HIVE_OrganizeRoutesStepFooter: {
    GO_BACK: 'Go back',
    CONTINUE: 'Continue',
  },
  HIVE_RouteSetupFooter: {
    CONTINUE: 'Continue',
  },
  HIVE_RouteSetupStep: {
    SELECT_DATE: 'Select a date',
    ROUTES_DATE: 'Route date',
    ROUTES_SUBTITLE: 'When will the routes be executed?',
  },
};

export default {
  messages: en,
  language: 'en',
};
